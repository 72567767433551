<template>
  <v-container>
    <v-row>
      <v-col>
        <h3>지역별 등록표</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <button class="button is-primary" @click="chartData0()">
          새로고침
        </button>
        <reactive :chart-data="datacollection"></reactive>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- <button class="button is-primary" @click="fillData1()">새로고침</button> -->
        <reactive1 :chart-data="datacollection1"></reactive1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import EventBus from "@/components/EventBus";
import Reactive from "@/components/Reactive";
import Reactive1 from "@/components/Reactive";

import { mapActions, mapGetters } from "vuex"; // mapGetters를 추가한다
const userStore = "userStore";

export default {
  name: "ApplicantChart",
  components: {
    Reactive,
    Reactive1,
  },
  data() {
    return {
      datacollection: {}, // instantiating datacollection with null
      datacollection1: {}, // instantiating datacollection with null
      desserts: [],
      sido: [],
      sidocode: [],
      dataB: [],
      labelsB: [],
      payload: null,
    };
  },
  computed: {
    ...mapGetters("userStore", {
      items3: "GE_SERVICE2",
    }),
  },
  created() {
    this.chartData1();
    //this.getRandomIntA();
    //this.fillData(); //anytime the vue instance is created, call the fillData() function.
    EventBus.$on("on_reactive_01", (payload) => {
      this.payload = payload;
      this.chartData2(); //anytime the vue instance is created, call the fillData() function.
      console.log("payload", payload);
    });
  },
  methods: {
    ...mapActions(userStore, ["GET_DATA", "GET_DATA1"]),
    async chartData0() {
      this.dataB = [];
      this.labelsB = [];
      await this.chartData1();
    },
    async chartData1() {
      var response = await this.getRandomIntA();
      if (response.data.repCode == "00") {
        this.fillData();
        this.fillData2();
      }
    },
    async chartData2() {
      var response = await this.getRandomIntB();
      if (response.data.repCode == "00") {
        this.fillData2();
      }
    },

    fillData() {
      this.datacollection = {
        // Data for the y-axis of the chart
        labels: this.sido,
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            // Data for the x-axis of the chart
            data: this.desserts,
          },
        ],
      };
    },
    fillData2() {
      this.datacollection1 = {
        // Data for the y-axis of the chart
        labels: this.labelsB,
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            pointBackgroundColor: "white",
            borderWidth: 1,
            pointBorderColor: "#249EBF",
            // Data for the x-axis of the chart
            data: this.dataB,
          },
        ],
      };
    },
    async getRandomIntA() {
      try {
        const getResult = await this.GET_DATA({
          fun_code: "chartselect",
          cas_id: this.mCPID,
        });
        if (getResult.data.repCode == "00") {
          console.log("getResult 결과", getResult.data);
          let data = getResult.data.repData;
          this.desserts = [];
          this.sidocode = [];
          this.sido = [];
          data.forEach((repData) => {
            this.desserts.push(Number(repData.CNT));
            this.sido.push(repData.ADDRESS1);
            this.sidocode.push(repData.ADDRCODE1);
          });
          console.log("정상 결과", this.desserts);
        } else {
          console.log("비정상 결과", getResult);
          this.desserts = getResult.data.repData;
        }
        return getResult;
      } catch (err) {
        console.error(err);
      }
    },
    async getRandomIntB() {
      try {
        const tt = Number(this.payload.index);
        const getResult = await this.GET_DATA1({
          fun_code: "chartselectB",
          cas_id: this.mCPID,
          addrcode1: this.sidocode[tt],
        });
        if (getResult.data.repCode == "00") {
          console.log("getResult : ", getResult.data);
          let data = getResult.data.repData;
          this.dataB = [];
          this.labelsB = [];
          data.forEach((repData) => {
            this.dataB.push(Number(repData.CNT));
            // this.labelsB.push(repData.SERVICE2DESC);
            const sss = repData.SERVICE2DESC.substring(1, 15);
            this.labelsB.push(sss);
          });
          console.log("정상 결과", this.dataB);
        } else {
          console.log("비정상 결과", getResult);
          this.data = getResult.data.repData;
        }
        return getResult;
      } catch (err) {
        console.error(err);
      }
    },
    // getRandomInt() {
    //   // JS function to generate numbers to be used for the chart
    //   return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    // },
    // getRandomInt1() {
    //   return this.desserts;
    // },
    // getRandomInt2() {
    //   this.getRandomIntA;
    //   return this.sido;
    // },
    // getfillData2_1() {
    //   return this.dataB;
    // },
    // getfillData2_2() {
    //   if (this.mCPID !== "") {
    //     this.getRandomIntB();
    //   }
    //   return this.labelsB;
    // },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
